'use strict'

###*
 # @ngdoc service
 # @name lpaDispatching.factory:IncidentPriorities

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'IncidentPriorities', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/incidents/priorities')
  ]
